.tag__container {
    @media(min-width:$bp-small){
        padding-left:$gutter;
        padding-right:$gutter;
    }
    @media(min-width:$bp-large){
        padding-left:$gutter*2;
        padding-right:$gutter*2;
    }
}
.tags {
    position: relative;
    padding:$baseline/2 $gutter 0 $gutter;
    background:lighten($bg-color, 2%);
    //background-color:$bg-color;
    @include clearfix;
    @media(min-width:$bp-small) {
        margin:4px 0 0 0;
    }
}
.tags__list {
    margin-bottom: $baseline/2;
}
.tag__item {
    display: inline-block;
    padding:.25rem $gutter/2 .25rem 25px;
    background:#fff;
    margin-right:$gutter/3;
    font:$gamma-size $tertiary-font;
    text-transform: uppercase;
    position:relative;
    cursor:pointer;
    transition: background-color 160ms ease, color 160ms ease;
        svg {
            position: absolute;
            left:2px;
            top:.2rem;
            fill:$body-font-color;
            transition: fill 160ms ease;
        }
        &:hover {
            color:#fff;
            //background:$dark-grey;
            background:$brand-light-blue;
                svg {
                    fill:#fff;    
                }
        }
}