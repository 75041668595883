.manage {}
.manage-list {
	@include hidden;
}
.active .manage-list {
	@include visible;
    padding-top:$baseline;
}
.manage-section {
	margin-bottom: $baseline;
	position: relative;
        .nav-icons {
            top:20px;
        }
}
.manage-title {
	margin-bottom: .25rem;
	cursor: pointer;
    display: block;
    padding-left:25px;
        &:before {
            display: block;
            content: '';
            position: absolute;
            left: $gutter;
            top: 34px;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            margin: -.15rem 0 0 0;
        }
}
.manage-item {
    list-style:none;
}
.active {
    .manage-title {
        &:before {
            transform: rotate(180deg)
        }
    }
    .manage-item {
        position: relative;
        margin-bottom:2px;
    }
    .manage-item__title {
        display: block;
        text-align:left;
        height:30px;
        line-height:30px;
        background:transparent;
            &:hover {
                text-decoration:underline;
            }
    }
    .manage-btn {
        position: absolute;
        right:1px;
        top:0;
        padding:6px;
    }
}