.filter {
    position: relative;
    padding:$baseline $gutter;
    //background-color:$light-grey;
    background:lighten($bg-color, 2%);
    @include clearfix;
    @media(min-width:$bp-small) {
        margin:0 $gutter;
    }

    @media(min-width:$bp-large) {
        margin-left:$gutter*2;
        margin-right:$gutter*2;
    }
}
.filter--less {
    .nav-icons {
        top:.5rem;
    }
}
.filter__form {
    @media(max-width:$bp-small){
        width:calc(100% - 100px);
        float:left;
    }
}
.filter__string {
    padding-left:35px;
    max-width:500px;
    width:calc(100% - 200px);
    background:#fff;
    border: 1px solid #fff;
    border-bottom:1px solid $grey;
    &::-webkit-input-placeholder {color:$grey;}
        &:focus {
            //border-bottom-color: $body-font-color;
            border-bottom-color: $brand-dark-blue;
            background:#fff;
        }
}
.sort {
    position: absolute;
    right:80px;
    top:28px;
    margin-right: 0;
    padding-top:0;
    a {
        display:block;
        padding:.25rem;
        background:#fff;
        &:hover{
            svg {
                fill:$body-font-color;
            }
        }
    }
    svg {
        display:block;
        fill:$grey;
        transition: fill 60ms ease;
    }
    &.active svg {
        fill:$body-font-color;    
    }
    button {
        width:100%;
        background-color:#fff;
        &.sort--active.sort--asc {
            background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% 0.75rem no-repeat;
        }
        &.sort--active.sort--desc {
            background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% 0.75rem no-repeat;
        }
        &.sort--asc:hover {
                cursor: pointer;
                background:lighten(grey, 40%) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% .75rem no-repeat;

        }
        &.sort--desc:hover {
            cursor: pointer;
            background:lighten(grey, 40%) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% .75rem no-repeat;
        }
    }
    @media(min-width:$bp-small) {
        display: none;
    }
}
