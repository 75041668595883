.loader {
    padding:$baseline $gutter;
    //background:lighten($bg-color, 2%);
    background:transparent;
    @include clearfix;
    @media(min-width:$bp-small) {
        margin:0 $gutter;
    }

    @media(min-width:$bp-large) {
        margin-left:$gutter*2;
        margin-right:$gutter*2;
    }
}
.loader--push-top {
    @media(min-width:$bp-small) {
        margin:$baseline 0;
    }
}
.loader__img {
    margin:0 auto;
}