//links 
.link-arrow-before:before {
    content:'';
    display:inline-block;
    vertical-align:middle;
    position:relative;
    height:8px;
    width:8px;
    margin-top:-1px;
    border-top:2px solid currentColor;
    border-right:2px solid currentColor;
    transform:rotate(45deg);
    margin-right:.5rem;
}
.link-arrow-after:after {
    content:'';
    display:inline-block;
    vertical-align:middle;
    position:relative;
    height:8px;
    width:8px;
    margin-top:-1px;
    border-top:2px solid currentColor;
    border-right:2px solid currentColor;
    transform:rotate(45deg);
    margin-left:.5rem;
}
.link-underline {
    text-decoration: underline
}