.caption {
    text-align: left;
    position: relative;
    padding:$baseline $gutter;
    //background-color:$light-grey;
    background:$bg-color;
    @include clearfix;
    margin-bottom:$baseline;
    @media(max-width:$bp-small) {
        display:block;
        padding-right:120px;
    }

    @media(min-width:$bp-small) {
        margin:$baseline 0;
    }
}
.caption--hidden {
    position: absolute;
    visibility: hidden;
}
.caption__h1 {
    text-align:left;
    margin-bottom: $baseline/4;
    font-family: $tertiary-font;
}
.caption__h2 {
    margin-bottom: $baseline/4;
}
.caption__date {
    display: block;
    text-align:left;
    font-size:$gamma-size;
    margin-bottom: $baseline/8
}
.caption__modification {
    margin-top:.5rem;
}