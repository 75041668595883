.table__container {
    margin-top:$baseline;
    @media(min-width:$bp-small){
        padding-left:$gutter;
        padding-right:$gutter;
    }
    @media(min-width:$bp-large){
        padding-left:$gutter*2;
        padding-right:$gutter*2;
        //height:400px;
        //overflow-y: scroll;
        //padding-top:32px;
    }
}
.table {
    width:100%;
    display: table;
    @media screen and (max-width:$bp-small){
        display: block;
    }
}
.table--fixed {
    table-layout: fixed;
}
.table--audit {
    thead th:last-child {
        width: 66.66%;
    }
}
.thead {
    display: table-header-group;
    @media screen and (max-width:$bp-small){
        max-height:0;
        overflow: hidden;
        position: absolute;
    }
}
.tbody {
    display: table-row-group;
    //margin-top:32px;
    @media screen and (max-width:$bp-small) {
        display: block;
    }
}
.tr {
    display: table-row;
    @media screen and (max-width:$bp-small){
        display: block;
        position: relative;
        padding-top:.75rem;
        padding-bottom:.75rem;
        padding-right:$gutter;
        width:100%;
    }
        &:hover {
            //background:darken($off-white, 1.5%);
            background:$hover-blue;
        }
}
.th,
.td {
    display: table-cell;
    text-align:left;
        @media screen and (max-width:$bp-small){
            display: block;
        }
}
.td {
    @media screen and (max-width:$bp-small) {
        width:100%;
        min-height:1.5rem;
        position: relative;
    }
    font-size:$gamma-size;
    vertical-align: middle;
}
.th {
    font:$gamma-size $tertiary-font;
    text-transform: uppercase;
    padding:.5rem 45px .5rem .5rem;
    background: $brand-dark-blue;
    color:#fff;
    position: relative;
    vertical-align: middle;
}
.sub-th__max-risk,
.sub-th__avg-risk,
.th__max-risk,
.th__avg-risk,
.th__compliance,
.compliance,
.avg-risk {
    @media screen and (min-width:$bp-small) {
        width:150px;
    }
}
.th__avg-compliance,
.sub-th__avg-compliance {
    @media screen and (min-width:$bp-small) {
        width:170px;
    }
}
.th--sub {
    background: lighten($dark-grey, 20%);
}
.th--actions {
    min-width: 0;
    width:35px;
    padding:0;
}
.th-delete {
    position: absolute;
    right:5px;
    top:.5rem;
    opacity:0;
}
.tr--faded .td:not(.td--actions){
    opacity:.25;
}
.th:hover .th-delete {
    opacity:1;
}
.tbody .tr:nth-child(even) {
    //background:darken($off-white, 1%);
    //background:lighten($bg-color, 2%);
    background:rgba($brand-dark-blue, .05);
        &:hover {
            //background:darken($off-white, 1.5%);
            background:$hover-blue;
        }
}
.tbody .tr__summary:hover {
    background:transparent !important;
}
.tbody .tr__summary:nth-child(even):hover {
    background:rgba($brand-dark-blue, .05) !important;
}
.td--actions {
    @media screen and (max-width:$bp-small) {
        position:absolute;
        top:.75rem;
        right:$gutter/2;
    }
    max-width:25px;
}
.tr__link {
    @media screen and (max-width:$bp-small) {
        display: block;
        padding:.25rem 0;
        position: relative;
        padding-left:170px;
        min-height:1.5rem;
    }
    padding:.75rem .5rem .75rem .5rem;
    display: block;
}

.tr__link--bg {
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    //margin-right:1px;
    //margin-bottom:1px;
    padding-left:1.5rem;
}
[data-th]:before { 
    @media screen and (max-width:$bp-small) {
        font:$gamma-size $tertiary-font;
        text-transform: uppercase;
        content: attr(data-th) ": ";
    }
}
.sort--active.sort--asc:not(.th--actions) {
    cursor: pointer;
    background:$brand-dark-blue url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23FFFFFF%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') right 25px top .5rem no-repeat;
}
.sort--active.sort--desc:not(.th--actions) {
        cursor: pointer;
        background:$brand-dark-blue url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23FFFFFF%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') right 25px top .5rem  no-repeat;

}
.sort--asc:not(.th--actions):hover {
        cursor: pointer;
        background:$brand-dark-blue url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23FFFFFF%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') right 25px top .5rem  no-repeat;

}
.sort--desc:not(.th--actions):hover {
    cursor: pointer;
    background:$brand-dark-blue url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23FFFFFF%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') right 25px top .5rem  no-repeat;
}

.sub-table__td .tr.compliance-tr {
    cursor:auto;
    &:hover {
        background: transparent !important;
    }
    &:nth-child(even) {
        background:rgba($brand-dark-blue, .05) !important;;
    }
}
.compliance-td {
    @media screen and (max-width:$bp-small) {
        display: block;
        padding:.25rem 0;
        position: relative;
        padding-left:170px !important;
        min-height:1.5rem;
        border-bottom:0 none;
        width:100% !important;
    }
    padding:.75rem .5rem .75rem .5rem;
    &:hover {
        background: transparent
    }
}

.compliance-td--figure {
    @media screen and (min-width:$bp-small) {
        width:100px;
    }
}

.has-subtable {
    .td {
        @media screen and (max-width:$bp-small) {
            display: block;
            padding:.25rem 0;
            position: relative;
            padding-left:170px !important;
            min-height:1.5rem;
            border-bottom:0 none;
        }
        padding:.75rem .5rem .75rem .5rem;
    }
    .td--actions {
        @media screen and (max-width:$bp-small) {
            position:absolute;
            top:.75rem;
            right:$gutter/2;
        }
    }
}
.has-subtable .tbody .tr:nth-child(even) {
    background:transparent;
    &:hover {
        background: rgba(10, 41, 108, 0.05);
    }
}
.sub-table__trigger {
    cursor: pointer;
    //border-bottom:3px solid $light-grey;
    border-bottom:3px solid $bg-color;
    @media screen and (min-width:$bp-small) {
        border-bottom:1px solid $bg-color;
    }
    &:after {
        width:100%;
        content:'';

    }
}
.sub-table__container {
    padding:$baseline 0;
    display:none;
}
.sub-table__trigger.active {
    border-bottom:0 none;
    position: relative;
    &+ .sub-table__container {
        display: block;
        @media screen and (min-width:$bp-small){
            display: table-row;
        }
    }
    &:hover {
        //background: transparent;
    }
}
.sub-table__trigger.loading > .td:first-of-type {
    position:relative;
    &:after {
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        content:'';
        height:12px;
        background:url('/Content/img/loader.gif') center no-repeat;
    }
    &:hover {
        //background-color:#fff;
    }
 }

.sub-table__container table {
    background-color:#fff;
        box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.has-subtable .sub-table__td {
    padding:$baseline $gutter !important;
    //background-color:darken($light-grey, 2%);
    background-color:$bg-color;
    position: relative;
    box-shadow:inset 0 2px 2px 0 rgba(0,0,0,.14), inset 0 2px 2px -2px rgba(0,0,0,.2), inset 0 1px 5px 0 rgba(0,0,0,.12);
        &:before {
            content:'';
            width: 0; 
            height: 0;
            border-right: 15px solid transparent;
            border-left: 15px solid transparent;
            border-top: 15px solid #fff;
            font-size: 0;
            line-height: 0;
            position: absolute;
            top:0;
            left:50%;
            margin-left: -8px;
            filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
        }
}
.sub-table__trigger.active:hover + .sub-table__container .sub-table__td:before {
    border-top: 15px solid $hover-blue;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.2));
}
.sub-table__td .tr {
    cursor:pointer;
    @media screen and (max-width:$bp-small){
        border-bottom:2px solid $bg-color;
    }
}
.sub-table__td .tr.sub-tr--archived:hover,
.sub-table__td .tr.sub-tr--archived:hover td,
.sub-table__td .tr--faded:hover,
.sub-table__td .tr--faded:hover .td {
    background-color:transparent;
	cursor: auto;
}
.sub-table__td td {
    border-bottom:1px solid $bg-color;
}
.sub-table__td .tr.sub-tr--archived {
    pointer-events: none;
}
.sub-tr--complete {
    opacity: .5;
    pointer-events: none;
}

.sub-th__additional-info,
.sub-th__justification-for-risk-change,
.sub-th__risk-comments {
    @media screen and (min-width:1275px){
        min-width:200px;
    }
    @media screen and (min-width:1550px){
        min-width:220px;
    }
}
.td .Linkify a {
    word-wrap:break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}
.sub-th__evidence-location {
    @media screen and (min-width:1275px){
        min-width:220px;
    }
}
.sub-th__question-number,
.sub-th__response {
    @media screen and (min-width:1275px){
        width:120px;
    }
}
.sub-th__question {
    @media screen and (min-width:1275px){
        width:250px;
        //min-width:150px;
        //max-width:200px;
    }
}

.table--mvc {
    .td {
        padding:.75rem .5rem;
    }
    .tbody .tr:nth-child(even):hover {
        background: rgba(10, 41, 108, 0.05);
    }
    .tr:hover {
        background:inherit;
    }
    .nav-actions__link:not(.th--actions) {
        display:inline;
            &:hover{
                background:transparent
            }
    }
    .nav-actions .nav-actions__link {
        display:block;
            &:hover{
                background: rgba(10, 41, 108, 0.05);
            }
    }
    @media screen and (min-width: $bp-small){
        .th--actions {
            text-align:center;
        }
        .th--actions,
        .td--actions {
            width:80px;
        }
    }
    @media screen and (max-width: $bp-small){
        .td {
            display: flex;
            padding: .25rem 1.5rem;
            min-height: 1.5rem;

            &:before {
                flex-basis: 50%;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
        .nav-actions__container--account {
            padding-top:0;
        }
        .td--actions {
            position:absolute;
                &:before {
                    content:'';
                }
        }
    }
 }

.table__clone {
    position: fixed;
    visibility: hidden;
    top:100%;
    @media(min-width:$bp-small){
        left:$gutter;
        right:$gutter;
        width:calc(100% - #{$gutter*2});
    }
    @media(min-width:$bp-large){
        left:$gutter*2;
        right:$gutter*2;
        width:calc(100% - #{$gutter*4});
    }
    .thead .th {
        margin-bottom:100%;
    }
    .tbody {
        position:fixed;
        z-index:-1;
        opacity:0;
        visibility: hidden;
    }
}
.table__clone--inset {
    @media(min-width:$bp-small){
        left:$gutter/2;
        right:$gutter/2;
        width:calc(100% - #{$gutter});
    }
    @media(min-width:$bp-large){
        left:$gutter;
        right:$gutter;
        width:calc(100% - #{$gutter*2});
    }
}
.on--sticky-header {
    .table__clone {
        visibility: visible;
        top:0;
    }
}

.table__summary {
    margin-top:2rem;
}
.table__summary-th {
    @media(max-width:$bp-small){
        display:inline-block;
        padding-left: 30px;
        background:transparent;
        color:$body-font-color;
    }
    border-bottom:1px solid white;
    background:lighten($bg-color, 2%);
    color:$body-font-color;
    font-family:$body-font-family;
    font-size:$beta-size;
    text-transform:none;
    padding:.75rem .5rem .75rem .5rem;
}
.table__summary-td {
    @media(max-width:$bp-small){
        display:inline-block;
        width:auto;
    }
    padding:.75rem $gutter/2;
    border-left:1px solid white;
    background:$brand-dark-blue;
    border-bottom:1px solid white;
    color:white;
}