/* Icons
-------------------------------------------------------------- */
.icon--edit,
.icon--delete,
.icon--transfer {
    position: relative;
    padding-left:40px;
    svg {
        position: absolute;
        left:10px;
        top:12px;
    }
}