/* Navigation
-------------------------------------------------------------- */
//primary nav
.nav-primary {
    position:fixed;
    z-index:4;
    width:50%;
    left:0;
    top:51px;
    bottom:0;
    padding:$baseline*2 0 20px $gutter;
    transform: translateX(-100%);
    will-change:transform;
    background:$hover-blue;
    visibility: hidden;
    max-width:$max-container-width;
        @media (min-width:$bp-medium){
            top:auto;
            visibility: visible;
            opacity:1;
            position:relative;
            padding:0;
            display:block;
            transform: none;
            background:transparent;
            width:100%;
                &:before,
                &:after {
                    content:'';
                    display:table;
                    width:100%;
                }
        }
}
.nav-primary__list {
    padding-left:0;
    margin-bottom: 0;
        @media (min-width:$bp-small){ 
            padding-left:$gutter/2;
        }
}
.nav-primary__item {
    list-style:none;
    padding-bottom:0;
    position: relative;
        @media (min-width:$bp-small) {
            display:inline-block;
            vertical-align:middle;

        }
}
.nav-primary__link {
    font:$beta-size $tertiary-font;
    display: block;
    padding:.5rem 0;
    text-transform:uppercase;
    color:$body-font-color;
        @media (min-width:$bp-medium) {
            font-size:$beta-size;
            padding:18px 15px 14px 15px;
            display:block;
            color:#fff;
            border-bottom: 3px solid rgba(255,255,255, .0);
                &:hover {
                    border-bottom: 3px solid rgba(255,255,255, .7);
                }
                &.active {
                        border-bottom: 3px solid rgba(255,255,255, 1);
                }
        }
}
.nav-primary__sub {
    @media(min-width:$bp-medium) {
        position: absolute;
        left: 0;
        top: 3em;
        overflow:hidden;
        visibility: hidden;
        //transition:transform .2s cubic-bezier(.4,0,.2,1),opacity .2s cubic-bezier(.4,0,.2,1),-webkit-transform .2s cubic-bezier(.4,0,.2,1);
        //transform: scale(0);
        //transform-origin: 0 0;
        background:#fff;
        box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
        z-index: 4;
    }
}
.nav-primary__item:hover .nav-primary__sub,
.nav-primary__item:focus .nav-primary__sub {
    transform: scale(1);
    visibility: visible;
}
.nav-primary__sub-list {
    list-style: none;
    padding-left:0;
    @media(min-width:$bp-medium) {
        margin-bottom:0;
        min-width:225px;
    }
}
.nav-primary__sub_item {
    display: block;
    width: 100%;
    list-style: none;
    padding-bottom: 0;
}
.nav-primary__sub-link {
    font:$beta-size $tertiary-font;
    text-transform: uppercase;
    display: block;
    width:auto;
    padding:$gutter/3 $gutter;
    text-align:left;
        @media(min-width:$bp-medium) {
            font-family:$body-font-family;
            text-transform:none;
				&.active,
                &:hover {
                    transition: background-color 60ms ease;
                    //background:lighten(grey, 40%);
                    background:$hover-blue;
                }
        }
}
.nav-primary__sub-toggle {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left:-10px;
    top:3px;
    svg {
        fill:$body-font-color;
    }
}

.nav-primary__item.active {
    .nav-primary__sub {
        max-height:100px;
    }
}
.nav-primary__item.animating {
    .nav-primary__sub {
        transition:max-height 200ms ease;
    }
}

.on--navigation {
    @media (max-width:$bp-medium){
        overflow:hidden;
        -ms-overflow-style: none !important;
        .nav-primary {
            transform: translateX(0);
            transition:transform 200ms ease;
            backface-visibility: hidden;
            visibility: visible;
            overflow-y:scroll;
        }
        .nav-primary__item {
            display:block;
            margin-bottom:1rem;
            font-size:1rem;
            margin-bottom:1rem;
            color:#fff
        }
        main {
            filter: blur(2px);
        }
    }
}
.animating--navigation {
    @media (max-width:$bp-medium){
        .nav-primary {
            transform:translateX(-100%);
            transition:transform 200ms ease;
        }
        main {
            filter: blur(0);
        }
    }
}
.no-js .nav-primary {
    @media (max-width:$bp-medium){
        transform: translateX(0);
        position:relative;
        visibility: visible;
        width:100%;
        padding-top:20px;
            .nav-primary__item {
                display:block;
                margin-bottom: .5rem;
                margin-bottom: .5rem;
            }
    }
}
//breadcrumb
.nav-breadcrumb {
    padding:$baseline/2 $gutter;
    background:$light-grey;
}
.nav-breadcrumb__link,
.nav-breadcrumb__item {
    display:inline-block;
    padding:0 .25rem 0 0;
    text-decoration:underline;
            &:not(:first-child):before {
                content:' > ';
                padding-right:.25rem;
                opacity:.5;
            }
            &:first-child:before {
                padding-right:0;
            }
}
.nav-breadcrumb__item {
    text-decoration: none;
}

.nav-account {}
.nav-account-username {
	color:#fff;
	font-size:$delta-size;
	position: relative;
	top:-.35rem
}

//account tasks
.nav-actions {
    position:absolute;
    right:0;
    max-height:0;
    min-width:208px;
    overflow:hidden;
    visibility: hidden;
    will-change: transform;
    transform: scale(0);
    transform-origin: 100% 0;
    transition:transform .2s cubic-bezier(.4,0,.2,1),opacity .2s cubic-bezier(.4,0,.2,1),-webkit-transform .2s cubic-bezier(.4,0,.2,1);
    background:#fff;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
}
.nav-actions__link:not(.th--actions) {
    display: block;
    width:100%;
    padding:$gutter/3 $gutter;
    text-align:left;
        &:hover {
            transition: background-color 60ms ease;
            //background:lighten(grey, 40%);
            background:$hover-blue;
        }
    &.sort--active.sort--asc {
        background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% 0.75rem no-repeat;
    }
    &.sort--active.sort--desc {
        background: #fff url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% 0.75rem no-repeat;
    }
    &.sort--asc:hover {
        background:lighten(grey, 40%) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2014l5-5%205%205z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% .75rem no-repeat;
    }
    &.sort--desc:hover {
        cursor: pointer;
        background:lighten(grey, 40%) url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23191919%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E') 95% .75rem no-repeat;
    }
}
.nav-actions__container {
    display: block;
    float:right;
    position: relative;
        &.active .nav-actions {
            max-height:1000px;
            visibility: visible;
            transform: scale(1);
            z-index:5;
        }
        &.animating .nav-actions {
            transform: scale(0);
            transition:transform .1s cubic-bezier(.4,0,.2,1),opacity .1s cubic-bezier(.4,0,.2,1),-webkit-transform .1s cubic-bezier(.4,0,.2,1);
        }
    @media(min-width:$bp-small) {
        margin-right:0;
    }
}
.nav-actions__container--account {
    margin-right:$gutter/2;
    padding:$gutter/2 0 0 0;
        @media($bp-small){
            margin-right:0;
        }
}
.filter .nav-actions__container {
    float:none;
    display: inline-block;
    vertical-align: middle;
    margin-left:1rem;
}
.nav-icons {
    @include clearfix;
    position: absolute;
    top:$baseline;
    right:$gutter;
    max-width:250px;
        .nav-actions__container {
            padding-top:0;    
        }
        .nav-actions__container-button {
            display: block;
            font:0/0 serif;
            padding: .25rem;
            background-color:#fff;
            @media(min-width:$bp-small) {
                display: none;
            }
        }
}
.nav-icons__icon {
    //fill:$dark-grey;
    transition: fill 60ms ease;
        &:hover {
            fill:$body-font-color;    
        }
}
//pagination
.nav-pagination {
    margin:3rem 0 0 0;
    padding:0;
    @media (max-width:$bp-small){
        padding-left:$gutter;
    }
}
.nav-pagination__item {
    display: inline-block;
    margin-bottom: 0;
    padding-bottom:0;
    margin-right:.5rem;
    margin-bottom:.5rem;
    min-width:32px;
    text-align: center;
}
.nav-pagination__link {
    display: inline-block;
    padding:0;
    line-height:32px;
    height:32px;
    width:32px;
    text-align: center;
    vertical-align:middle;
    background-color:$bg-color;
    transition:background-color 160ms ease;
        &:hover {
            background-color:darken($bg-color, 5%);
        }
        &.active {
            background-color:$brand-dark-blue;
            color:white;
        }
}

.nav-incremental {
    text-align:right;
    margin-bottom:$baseline /2;
}
.nav-incremental__link {
    display:inline-block;
    background-color:#fff;
    padding:0rem .5rem .25rem .5rem;
    margin:0 0 0 10px;
    cursor:pointer;
    position: relative;
        svg {
            position:relative;
            top: .35rem;
        }
        &:hover{
            background-color:$brand-light-blue;
            color:#fff;
                svg {
                    fill:#fff;
                }
        }
}
.nav-incremental__link--previous svg {
    left: -.5rem;
}
.nav-incremental__link--next svg {
    left: .5rem;
}
.nav-incremental__total {
    display:inline-block;
    padding:.5rem 1rem;

}