/*
.modal {
    @include hidden;

.modal__inner {
    width:100%;
    background:#fff;
    position: relative;
    transform-origin: center;
    background:#fff;
    border-top:1.5rem solid $light-grey;
    padding-bottom: $baseline*2;
}
.on--modal .modal {
    @include visible;
    overflow-y:scroll;
    position:fixed;
    top:1.5rem;
    bottom:0;
    width:100%;
    z-index:1;
    opacity:0;
    transition: opacity 360ms ease;
}
.modal__inner .page-inset {
    transform:scale(0.6);
    opacity:0;
    transition: transform 200ms ease, opacity 360ms ease;
}
.on--modal .modal {
    opacity:1;
}
.on--modal .modal__inner .page-inset {
    transform:scale(1);
    opacity:1;
    z-index:3;
}
.on--modal .modal__close {
    position:fixed;
    z-index:2;
    top:$baseline*2;
    left:$gutter;

}
*/
.modal__inner {
    overflow-y:scroll;
}
.ReactModal__Overlay--after-open .modal__close {
    cursor:pointer;
    position: absolute;
    top:40px;
    right:30px;
    @media(min-width:$bp-small) {
        position: fixed;
        top:60px;
        right:60px;
    }
}