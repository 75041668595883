.autocomplete{
	position: relative;
}
.autocomplete--questions {
    .filter__string {
        width:100%;
        max-width:650px;
    }
    @media (min-width:$bp-small){
        top:1.8rem;
    }
}
.autocomplete-container {
    position: absolute;
	left: 0;
	z-index: 1;
    width:100%;
	max-width:500px;
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	margin: .75em 0 0;
	background: #fff;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
	text-shadow: none;
    display: block;
    transition-timing-function: ease;
}
.autocomplete-container.active {
    transition: .3s cubic-bezier(.4,.2,.5,1.4);
    transform-origin: 1.43em -.43em;
}
.autocomplete-container:before {
    content: "";
    position: absolute;
    top: -.43em;
    left: 1em;
    width: 0;
    height: 0;
    padding: .4em;
    background: #fff;
    border: inherit;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
}
.autocomplete-suggestion__item {
    position: relative;
    padding: .2em .5em;
    cursor: pointer;
    list-style:none;
        &:hover,
        &.focus {
            background:lighten(grey, 40%);
            color: $body-font-color;
        }
}
.autocomplete.active .form-row__inset-icon {
    fill: $body-font-color;
}


.visually-hidden {
    @include hidden;
}
div.awesomplete > input {
	display: block;
}

div.awesomplete > ul {
	position: absolute;
	left: 0;
	z-index: 1;
    width:100%;
	max-width:500px;
	box-sizing: border-box;
	list-style: none;
	padding: 0;
	margin: .75em 0 0;
	background: #fff;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
	text-shadow: none;
}

div.awesomplete > ul[hidden],
div.awesomplete > ul:empty {
	display: none;
}
/*
@supports (transform: scale(0)) {
	div.awesomplete > ul {
		transition: .3s cubic-bezier(.4,.2,.5,1.4);
		transform-origin: 1.43em -.43em;
	}
	
	div.awesomplete > ul[hidden],
	div.awesomplete > ul:empty {
		opacity: 0;
		transform: scale(0);
		display: block;
		transition-timing-function: ease;
	}
}
    */

	div.awesomplete > ul:before {
		content: "";
		position: absolute;
		top: -.43em;
		left: 1em;
		width: 0; height: 0;
		padding: .4em;
		background: white;
		border: inherit;
		border-right: 0;
		border-bottom: 0;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
	}

	div.awesomplete > ul > li {
		position: relative;
		padding: .2em .5em;
		cursor: pointer;
        list-style:none;
	}
	
	div.awesomplete > ul > li:hover {
        background:lighten(grey, 40%);
		color: $body-font-color;
	}
	
	div.awesomplete > ul > li[aria-selected="true"] {
        background:lighten(grey, 40%);
		color: $body-font-color;
	}
    /*
    div.awesomplete mark {
        background: $light-grey;
        font-style: normal
    }
		div.awesomplete mark {
			background: hsl(65, 100%, 50%);
		}
		
		div.awesomplete li:hover mark {
			background: hsl(68, 101%, 41%);
		}
		
		div.awesomplete li[aria-selected="true"] mark {
			background: hsl(86, 102%, 21%);
			color: inherit;
		}
        */