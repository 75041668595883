@media print {
    .questionnaire {
        margin-top:$baseline;
    }
    .questionnaire__title {
        font-size:11pt !important;
        margin-bottom:$baseline;
    }
    .questionnaire__field {
        margin-bottom:1rem;
             &:after {
                content:'';
                display: block;
                position: absolute;
                width: 50%;
                left: 85px;
                border-bottom:1px solid #191919;
            }
            &:last-child:after {
                width:25%;
            }
    }
    .questionnaire__table {
        display:block;
        width:auto;
        position:relative;
        @include clearfix;
        margin-bottom:$baseline;
        border-left: 1px solid #191919;
        border-right: 1px solid #191919;
        border-bottom: 1px solid #191919;
    }
    .questionnaire__table-tbody_wrapper:before {
        position:absolute;
        content:'';
        top:0;
        bottom:0;
        left:63%;
        width:1px;
        background:#191919;
    }
    .questionnaire__table-tbody {
        display:block;
        width:auto;
        clear:left;
            &:before,
            &:after {
                content:'';
                position:absolute;
                top:0;
                bottom:0;
                left:10%;
                width:1px;
                background:#191919;
            }
            &:after {
                left:50.99%;
            }
            .questionnaire__table-row {
                padding:.5rem 0 0 .5rem;
            }
    }
    .questionnaire__table-row {
        display:block;
        width:auto;
        clear:left;
        position: relative;
        page-break-inside: avoid !important;
        overflow:hidden;
            &:before {
                content:'';
                display: block;
                clear:left;
                border-top: 1px solid #191919;
            }
    }
    .questionnaire__table-row--thead {
    }
    .questionnaire__table-th {
    }
    .questionnaire__table-th {
        text-align:center;
            /*
            &:nth-child(1n){
                width:2%;
            }
            &:nth-child(2n),
            &:nth-child(4n){
                text-align:left;
                width:40%;
            }
                */
    }
    .questionnaire__table-th,
    .questionnaire__table-td {
        display:block;
        float:left;
        vertical-align:top;
        //border-top: 1px solid #191919;
        //border-bottom: 1px solid #191919;
        position: relative;
        padding:.5rem !important;
        page-break-inside: avoid !important;
        border-left:0 none;
        border-bottom:0 none;
        min-height:36px;
    }
    .questionnaire__table-th--number,
    .questionnaire__table-td--number {
        width:10%;
        text-align:center;
    }
    .questionnaire__table-th--questions,
    .questionnaire__table-td--questions {
        position:relative;
        width:40.9999%;
        text-align:left;
        text-align:left;
    }
    .questionnaire__table-th--questions {
        //border-left: 1px solid #191919;
        //border-right: 1px solid #191919;
    }
    .questionnaire__table-th--compliance,
    .questionnaire__table-td--compliance {
        width:12%;
        text-align:center;
    }
    .questionnaire__table-th--evidence,
    .questionnaire__table-td--evidence {
        width:37%;
        text-align:left;
    }

    .questionnaire__question {
        display:inline-block;
        vertical-align:top;
        width:calc(100% - 220px);
        position: relative;
        page-break-inside: avoid !important;
    }
    .questionnaire__topic {
        font-size:12pt !important;
        page-break-after: avoid !important;
        margin-bottom:$baseline/2;
        margin-top:1rem !important;
    }
    .questionnaire__table-td--questions-boolean {
        min-height:80px;
    }
    .questionnaire__boolean {
        padding-left:10px !important;
        text-align:left;
    }
    
    .questionnaire__boolean-item {
        display:block;
        position:relative;
            &:before {
                content:'';
                display:inline-block;
                width: 20px;
                height: 20px;
                border: 1px solid $body-font-color;
                vertical-align: middle;
                margin-right: 10px;
            }
            &:first-child {
                margin-bottom:.5rem;
            }
    }
 }