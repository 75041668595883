.tabs {
}
.tabs-navigation {
    padding:0 5px;
    @media(min-width:$bp-small){
        padding:0 $gutter;
    }
}
.tabs-menu {
    padding:0;
    margin:0;
    list-style:none;
}
.tabs-menu-item {
    @media(max-width:$bp-small) {
        font-size:$beta-size;
        padding:$baseline/3 $gutter/4;
    }
    display: block;
    float:left;
    padding:$baseline/2 $gutter;
    background:#fff;
    opacity:.6;
    font:$alpha-size $tertiary-font;
    margin-right:2px;
    cursor: pointer;
        &:hover {
            opacity:1;
        }
}
.tabs-menu-item.is-active {
    opacity:1;
}
.tab-panel {
    clear:left;
    background:#fff;
    padding-top:$baseline;
    padding-bottom:$baseline*7;
    top:0;
    z-index:2;
    min-height:70vh;
}