.datepicker__tether-element-attached-top .datepicker__triangle, .datepicker__tether-element-attached-bottom .datepicker__triangle, .datepicker__year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}
.datepicker__tether-element-attached-top .datepicker__triangle, .datepicker__tether-element-attached-bottom .datepicker__triangle, .datepicker__year-read-view--down-arrow, .datepicker__tether-element-attached-top .datepicker__triangle::before, .datepicker__tether-element-attached-bottom .datepicker__triangle::before, .datepicker__year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}
.datepicker__tether-element-attached-top .datepicker__triangle::before, .datepicker__tether-element-attached-bottom .datepicker__triangle::before, .datepicker__year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.datepicker__tether-element-attached-top .datepicker__triangle {
  top: 0;
  margin-top: -8px;
}
.datepicker__tether-element-attached-top .datepicker__triangle, .datepicker__tether-element-attached-top .datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}
.datepicker__tether-element-attached-top .datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.datepicker__tether-element-attached-bottom .datepicker__triangle, .datepicker__year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}
.datepicker__tether-element-attached-bottom .datepicker__triangle, .datepicker__year-read-view--down-arrow, .datepicker__tether-element-attached-bottom .datepicker__triangle::before, .datepicker__year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}
.datepicker__tether-element-attached-bottom .datepicker__triangle::before, .datepicker__year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.datepicker {
  font-size: 11px;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  display: inline-block;
  position: relative;
}

.datepicker__triangle {
  position: absolute;
  left: 50px;
}

.datepicker__tether-element-attached-bottom.datepicker__tether-element {
  margin-top: -20px;
}

.datepicker__header {
  text-align: center;
  background-color: $brand-dark-blue;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-top: 8px;
  position: relative;
    .datepicker__day {
        color:#fff;
    }
}

.datepicker__current-month {
  margin-top: 0;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}
.datepicker__current-month--hasYearDropdown {
  margin-bottom: 16px;
}

.datepicker__navigation {
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: 6px solid transparent;
}
.datepicker__navigation--previous {
  left: 10px;
  border-right-color: #fff;
}
.datepicker__navigation--previous:hover {
  border-right-color: $brand-light-blue;
}
.datepicker__navigation--next {
  right: 10px;
  border-left-color: #fff;
}
.datepicker__navigation--next:hover {
  border-left-color: $brand-light-blue;
}
.datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #fff;
}
.datepicker__navigation--years-previous:hover {
  border-top-color: $brand-light-blue;
}
.datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #fff;
}
.datepicker__navigation--years-upcoming:hover {
  border-bottom-color: $brand-light-blue;
}

.datepicker__week-day {
  color: #ccc;
  display: inline-block;
  width: 28px;
  line-height: 24px;
}

.datepicker__month {
  margin: 5px;
  text-align: center;
}

.datepicker__day {
  color: $body-font-color;
  display: inline-block;
  width: 24px;
  line-height: 24px;
  text-align: center;
  margin: 2px;
  cursor: pointer;
}
.datepicker__day:hover {
  background:$light-grey;
}
.datepicker__header .datepicker__day:hover {
  opacity:.7;
}
.datepicker__day--today {
  font-weight: bold;
}
.datepicker__day--selected, .datepicker__day--in-range {
  background-color: $brand-dark-blue;
  color: #fff;
}
.datepicker__day--selected:hover {
  background-color: $brand-dark-blue;
}
.datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}
.datepicker__day--disabled:hover {
  background-color: transparent;
}

.datepicker__input-container {
  position: relative;
}

.datepicker__input {
  position: relative;
}
.datepicker__input:focus {
  outline: none;
}
.datepicker__input:not(:valid) ~ .close-icon {
  display: none;
}

.datepicker__year-read-view {
  width: 50%;
  left: 25%;
  position: absolute;
  bottom: 25px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.datepicker__year-read-view:hover {
  cursor: pointer;
}
.datepicker__year-read-view:hover .datepicker__year-read-view--down-arrow {
  border-top-color: #b3b3b3;
}
.datepicker__year-read-view--down-arrow {
  border-top-color: #ccc;
  margin-bottom: 3px;
  left: 5px;
  top: 9px;
  position: relative;
  border-width: 6px;
}
.datepicker__year-read-view--selected-year {
  right: 6px;
  position: relative;
}

.datepicker__year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border: 1px solid #aeaeae;
}
.datepicker__year-dropdown:hover {
  cursor: pointer;
}

.datepicker__year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.datepicker__year-option:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.datepicker__year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker__year-option:hover {
  background-color: #ccc;
}
.datepicker__year-option:hover .datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}
.datepicker__year-option:hover .datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}
.datepicker__year-option--selected {
  position: absolute;
  left: 30px;
}

.close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;
}
.close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}

.datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
}

.datepicker__tether-element {
  z-index: 2147483647;
}
