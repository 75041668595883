// Path alias
$assetPath:                '/content';
$uiImgPath:                '#{$assetPath}/img';
$fontPath:                 '#{$assetPath}/fonts';

// Colors
// -------------------------
$body-font-color:           #191919 !default;

$brand-dark-blue:           #0A296C !default;
$brand-light-blue:          #0093B5 !default;
$brand-red:                 #981B1F !default;
$brand-purple:              #450078 !default;

$hover-blue:                #E7EAF0 !default;

$stone:                     #DEDECA !default;
$bg-color:                  #f1f1f1 !default;

$grey:		                #bbb !default;
$off-white:                 #f1f1f1 !default;
$light-grey:                #eee !default;
$dark-grey:                 #666 !default;
$error-color:               #9f001e !default;
$warning-color:             #B88100 !default;
$success-color:             #7BB808 !default;

// Type
// --------------------------------------------------
// Font weights
$thin:                      100;
$light:                     300;
$regular:                   400;
$semibold:                  600;
$bold:                      700;
$thick:                     800;

//font-size
$tera-size:                 2rem !default;
$giga-size:                 1.75rem !default;
$mega-size:                 1.45rem !default;
$alpha-size:                1.125rem !default;
$beta-size:                 1rem !default;
$gamma-size:                .875rem !default;
$delta-size:                .75rem !default;
$epsilon-size:              .625rem !default;

$baseline:                  1.5rem !default;
$base-font-family:          'Roboto', sans-serif !default;
$base-font-weight:          $regular !default;
$base-font-size:            100% !default;
$base-line-height:          1.5 !default;

$body-font-family:       	$base-font-family !default;
$bold-font-family:          $base-font-family !default;


$secondary-font:            'Roboto Mono', sans-serif !default;
$tertiary-font:             'Roboto Condensed', sans-serif !default;
$header-font:               $tertiary-font !default;	


// Layout
// --------------------------------------------------
$max-container-width:       1400px !default;
$gutter:				    30px !default;
$columns:                   12 !default;

// Shared
$border-radius:             0 !default;
$border-color:              $grey !default;
$border-width:              1px !default;

// Breakpoints
// --------------------------------------------------
$bp-xsmall:                 32em !default;
$bp-small:                  62em !default;
$bp-medium:                 62em !default;
$bp-large:                  $max-container-width !default;