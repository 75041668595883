.risk-matrix {
    position: relative;
        table {
            width:100%;
            margin-bottom: $baseline/2;
        }
}
.risk-matrix__th {
    @media(max-width:$bp-small){
        display:none;
    }
    font:$gamma-size $tertiary-font;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    padding:.25rem .5rem .5rem .25rem;
}
.risk-matrix__th--h {
    text-align: right;
    padding:.5rem .5rem .5rem .5rem;
}
.risk-matrix__item {
    border:1px solid $light-grey;
        input {
            display: none
        }
        label {
            font-size:$gamma-size;
            display:inline-block;
            vertical-align:middle;
            text-align: center;
            margin:0;
            padding:.25rem;
            opacity:.4;
                &:hover {
                    opacity:.9;
                    transition:opacity 60ms ease;
                }
                @media(min-width:$bp-small) {
                    font-size:$beta-size;
                    padding:.5rem;
                }
        }
}
.risk-matrix__item--low label{
    background-color:#00FF00;
}
.risk-matrix__item--med label{
    background-color:#FFFF00;
}
.risk-matrix__item--high label{
    background-color:#FF9900;
}
.risk-matrix__item--vhigh label {
    background-color:#FF0000;
}
.risk-matrix__item input:checked + label{
    opacity:1;
    transition:opacity 60ms ease;
}