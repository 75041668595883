/* Grid
-------------------------------------------------------------- */
@function column-calc($num, $spacing:$gutter){
    @return calc(((99.999% / #{$columns}) * #{$num}) - (#{$spacing}));
}
@function column-calc-fallback($num){
    @return ((100 / $columns) * $num) - 5%;
}
@mixin column($num) {
	width: column-calc-fallback($num);
	width: column-calc($num);
}

.cf {
    @include clearfix;
}
.row {
    clear:both;
    margin-left:-$gutter;
        &:before,
        &:after {
            content:'';
            display: table;
            clear:left;
        }
}
.col {
	float:left;
    margin-left:#{$gutter};
}

/* Sample column classes, roll your own for your own needs using the column calulation helpers above */
.xsmall-12 {
    @media(max-width:$bp-small){
        float:none;
        width:auto;
    }
}
@media(min-width:$bp-small){
    .small-3 {
        @include column(3);
    }
    .small-4 {
        @include column(4);
    }
    .small-6 {
        @include column(6);
    }
    .small-9 {
        @include column(9);
    }
    .small-10 {
        @include column(10);
    }
    .small-2 {
        @include column(2);
    }
    @media(max-width:$bp-small){
        .small-12 {
            max-width:$max-container-width;
            width:100%;
            margin-left:0;
        }
    }
}
.medium-2 {
    @media (min-width:$bp-medium) {
        @include column(2);
    }
}
.medium-3 {
    @media (min-width:$bp-medium) {
        @include column(3);
    }
}
.medium-4 {
    @media (min-width:$bp-medium) {
        @include column(4);
    }
}
.medium-6 {
    @media (min-width:$bp-medium) {
        @include column(6);
    }
}
.medium-9 {
    @media (min-width:$bp-medium) {
        @include column(9);
    }
}
.medium-10 {
    @media (min-width:$bp-medium) {
        @include column(10);
    }
}
.medium-12 {
    @media (min-width:$bp-medium) {
        margin-left:0;
        float:none;
        width:auto;
    }
}
.large-3 {
    @media (min-width:$bp-large) {
	   @include column(3);
    }
}
.large-4 {
    @media (min-width:$bp-large) {
	   @include column(4);
    }
}
.large-6 {
    @media (min-width:$bp-large) {
	   @include column(6);
    }
}
.large-9 {
    @media (min-width:$bp-large) {
	   @include column(9);
    }
}
.large-12 {
    @media (min-width:$bp-large) {
        margin-left:0;
	    float:none;
        width:auto;
    }
}

main {
    padding:$baseline 0;
    min-height:80vh;
}

//Grid modifiers
.col--full {
    width:100%;
    padding:0 $gutter;
}
.col--centre {
    margin:0 auto;
}
.col--right {
    text-align: right;
}
.push--right {
    padding-right:$gutter
}
.push--top {
    padding-top:$baseline;
}
 .push--bottom {
    padding-bottom:$baseline;
 }