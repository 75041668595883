/* Normalise
-------------------------------------------------------------- */
*,
*:before,
*:after { 
	-moz-box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	box-sizing: border-box;
}

html, 
body {
    background: #fff;
    overflow-x: hidden;
    //height:100%;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video, main {
	background: transparent;
	border: 0;
	font-size: 100%;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}
    
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { 
	display:block;
}


strong, 
b {
	font-weight:$bold;
}
    
em, 
i {
	font-style:italic;
}
    
ol, ul {
	list-style: none;
}
    
blockquote, q {
	quotes: none;
}
    
blockquote:before, 
blockquote:after,
q:before, 
q:after {
	content: '';
	content: none;
}
    
ins {
    text-decoration: none;
}

mark {
    font-style: normal;
    font-weight: $regular;
}

del {
    text-decoration: line-through;
}
	
dfn {
    font-style: italic;
}
	
table {
    border-collapse: collapse;
    border-spacing: 0;
}
	
td { 
	vertical-align: top; 
}

hr {
	border: 0;
    display: block;
    height: 1px; 
    margin: 0;
    padding: 0;
}
	
address {
	font-style: normal;
}
legend {
    border: 0;
    padding: 0;
    white-space: normal;
}
	
figure {
    margin: 0;
}

[hidden] {
    display: none;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
	
sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}
	
	
img {
    display:block;
	border: 0;
	vertical-align: middle;
    max-width: 100%;
    height:auto;
}

a {
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    outline: 0;
    text-decoration: none;
}

input, 
button,
select {
	-moz-appearance: none;
	-webkit-appearance: none;
}
	
input, 
button, 
select {
	border: 0 none;
    background:#fff;
	border-radius: 0;
	outline: 0;
	padding: 0;
	vertical-align: middle;
}
button {
    background:transparent;
}
select::-ms-expand{
  display:none;
}
	
input[type="search"] {
	border-radius: 4px;
	-moz-appearance: none;
	-webkit-appearance: none;
}
	
input[type="checkbox"] {
	-moz-appearance: checkbox;
	-webkit-appearance: checkbox;
}
	
input[type="radio"] {
	-moz-appearance: radio;
	-webkit-appearance: radio;
}

input[type="range"] {
	-moz-appearance: slider-horizontal;
    -webkit-appearance: slider-horizontal;
}
	
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  	display: none;
}

button::-moz-focus-inner, 
input::-moz-focus-inner { 
	border: 0; 
	padding: 0; 
}

button,
input,
select,
textarea {
    font-size: 100%; // 1
    line-height: normal; // 4
    margin: 0; // 2
    vertical-align: baseline; // 3
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border:0 none;
    cursor: pointer; // 1
    -webkit-appearance: button; // 2
}

button[disabled],
input[disabled] {
    cursor: default;
} 
textarea { 
	overflow: auto; 
	vertical-align: top; 
	resize: vertical; 
    width:100%;
}
textarea:hover, 
textarea:active, 
textarea:focus {
	outline: none;
}