/*
.tooltip__container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.tooltip {
    position: absolute;
    display: inline-block;
    top: -50px;
    left:-64px;
    height:40px;
    width:200px;
    text-align:center;
    background: #fff;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12);
    padding:$baseline/3 $gutter;
    opacity: 0;
    cursor:auto;
        &:after {
            width:10px;
            height:10px;
            content:'';
            position: absolute;
            bottom:-5px;
            left: 50%;
            margin-left:-10px;
            transform: rotate(45deg);
            background: #fff;
            box-shadow:0 3px 1px -1px rgba(0,0,0,.14);
        }
}

.tooltip__trigger:hover + .tooltip {
    opacity:1;
    animation: tooltip 160ms ease;
}

@keyframes tooltip {
    from {
        opacity:0;
        transform: translateY(20px)
    }
    to {
        opacity:1;
        transform:translateY(0);
    }
}
*/
//react tooltip
.tooltip {
    text-transform: none;
    max-width:350px !important;
    font:.9rem/1.4 $body-font-family !important;
}

//vanilla toolip
.tooltip__container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
.js-tooltip:hover  + .tooltip__window {
    opacity:0.9;
    visibility:visible;
}
.tooltip__window {
    border-radius: 3px;
    display: inline-block;
    font-size: .875rem;
    left: -999em;
    opacity: 0;
    padding: 8px 21px;
    position: fixed;
    pointer-events: none;
    transition: opacity 0.3s ease-out , margin-top 0.3s ease-out, margin-left 0.3s ease-out;
    top: -999em;
    visibility: hidden;
    z-index: 999;
    color: #fff;
    background-color: #222;
    margin-top:-50px;
        &:before,
        &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
        }
        &:before {
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            bottom: -8px;
            left: 50%;
            margin-left: -10px;
        }
        &:after {
           border-left: 8px solid transparent;
           border-right: 8px solid transparent;
           bottom: -6px;
           left: 50%;
           margin-left: -8px;
        }
        &:after {
            border-top: 6px solid #222;
        }
}