/* Buttons
-------------------------------------------------------------- */
.btn {
	display: inline-block;
	font-size:1rem;
	outline: none;
	padding: .5rem 1rem;
	position: relative;
	text-decoration: none;
    width:auto;
    margin:0;
    border:0 none;
    color:#fff;
    border-radius:$border-radius;
    cursor:pointer;
    text-align:center;
        &:focus {
        }
        &:hover {
        }
        &:disabled {
            opacity:.5;    
        }
}
.btn-close {
    visibility: hidden;
    opacity:0;
    position:absolute;
    top:0;
    bottom:0;
}
.btn-hamburger {
    @include ir;
    height:50px;
    width:20px;
    position: absolute;
    border-top:3px solid #fff;
    top:16px;
    left:$gutter;
    z-index:3;
        &:before,
        &:after {
            z-index:3;
            height:3px;
            line-height:3px;
            content:'';
            width:20px;
            position: absolute;
            left:0;
            background:#fff;
        }
        &:before {
            top:3px;
        }
        &:after {
            top:9px;
        }
        @media screen and (min-width:$bp-medium) {
            @include hidden;
        }
}
.on--navigation {
    @media (max-width:$bp-small) {
        .btn-hamburger {
            border-top:2px solid rgba(0,0,0, .0);
            transition: border-color 100ms ease-out;
                &:before,
                &:after {
                    background-color:#fff;
                    transition: transform 100ms ease-out, background-color 100ms ease-out;
                }
                &:before {
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg) translateX(4px) translateY(-4px);
                }
        }
    }
}
.animating--navigation .btn-hamburger {
    border-top:2px solid #fff;
    transition: border-color 100ms ease-in, border-top 100ms ease-in;
        &:before,
        &:after {
            transition: transform 100ms ease-in, border-top 100ms ease-in;
            transform: rotate(0);
        }
}
.no-js .btn-hamburger {
    display:none;
}
.btn--inline {
    display: inline-block;
    width:auto;
    vertical-align: middle;
}
.btn--submit {
    //background:$dark-grey;
    background:$brand-dark-blue;
	padding: .5rem 1.5rem;
    transition: background-color 60ms ease;
        &:focus,
        &:not(:disabled):hover {
            background-color:$brand-light-blue;    
        }
}
.btn--cancel {
    color:$brand-dark-blue;
    margin-right:5px;
        &:hover {
            color:$brand-light-blue;
        }
}

//skip to content
.btn-skip {
    position: absolute;
    visibility: hidden;
        &:focus {
            position: fixed;
            visibility: visible;
            background:#fff;
            display:block;
            left:0;
            right:0;
            top:0;
            outline:0;
            z-index:3;
            text-align: center;
        }
}

.btn__print,
.btn__add,
.btn__edit {
    background:#fff;
    display: block;
    padding: .25rem;
    margin: 0 0 0 1rem;
        svg {
            display: block;
            fill:$body-font-color;
            transition: fill 100ms ease;
        }
        &:focus svg {
            fill:$brand-light-blue;    
        }
}
.toggler-close {
    position: fixed;
    z-index:4;
    left:-100%;
    width:100%;
    bottom:0;
    top:0;
}
.active > .toggler-close {
    left:0;
}

.btn__toggle {
    cursor:pointer;
    position:relative;
    padding-right: 50px;
    width:100%;
    z-index:2;
    font:$alpha-size $tertiary-font;
        &:after {
            content:'';
            position:absolute;
            right:0;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            margin:-.15rem 0 0 10px;
        }
}
.on--form-context .btn__toggle {
        &:after {
            content:'';
            position:absolute;
            right:0;
            top:50%;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: currentColor transparent transparent transparent;
            transform:rotate(-180deg);
    }
}