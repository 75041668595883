.print-only {
    @include hidden;
}
@media print {
    * {
        color: black !important;
        text-shadow: none !important;
        -webkit-filter: none !important;
        filter: none !important;
        -ms-filter: none !important;
        padding-top:0 !important;
        padding-left:0 !important;
        padding-right:0 !important;
        margin-top:0 !important;
        margin-left:0 !important;
        margin-right:0 !important;
        box-shadow:none !important;
    }
    
    body {
        border: 0 none !important;
        font-size: 10pt !important;
        background-color: transparent !important;
        -webkit-print-color-adjust: exact !important;
    }
    
    @page {
        margin: 0.5cm;
    }
    
    .print-only {
        @include visible;
    }
    
    a img {
        text-decoration: none !important;
        border: 0 none !important;
    }
    
    a,
    a:visited {
        color: #444 !important;
        text-decoration: underline;
    }
    
    .btn-hamburger,
    .tags,
    .banner,
    .nav-icons,
    .form,
    .nav-primary,
    .nav-actions__container,
    .filter,
    .tabs-navigation,
    .dataset-form,
    .td--actions,
    .th--actions,
    .has-subtable .sub-table__td:before {
        display: none !important;
    }
    .caption {
        background-color: transparent !important;
        padding-bottom:0 !important;
    }
    h1 {
        font-size:14pt !important;
    }
    .col:not([class*='print']) {
        width:100% !important;
        float:none !important;
    }
    main {
        padding-top:20px;
    }
    .table {
        width:100% !important;
    }
    .th {
        //border-bottom: 1px solid #000;
        font-size: 9pt !important;
        background-color:transparent !important;
    }
    .th,
    .td {
        width:auto !important;
        padding:.25rem !important;
        page-break-inside: avoid;
        vertical-align: top;
            a,
            a:visited {
                text-decoration: none !important;
            }
    }
    .tr {
        page-break-inside: avoid;
    }
    .has-subtable .sub-table__td {
        padding:0 5px;
    }
    .table__summary-th {
        border-bottom: 0 none;
        background: transparent;
        font-weight:$regular;
        text-align:left;
        padding:.75rem .5rem .75rem .5rem !important
    }
    .table__summary-td {
        border:0 none;
        background: transparent;
        color: $body-font-color;
        font-size:1rem;
        padding:.75rem .5rem .75rem .5rem !important
    }
    .has-subtable .sub-table__td {
        background-color: transparent;
        box-shadow: 0 none;
    }
    .sub-table__trigger.active,
    .sub-table__container {
        background-color: transparent;
        page-break-inside:auto;
    }
    .sub-table__td .th {
        border-bottom:0 none;
    }
    .compliance-td b {
        font-weight:$regular;
        font-size:1rem;
    }
}