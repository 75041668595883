/* Forms
-------------------------------------------------------------- */
.login-main {
    @media(min-width:$bp-xsmall){
        margin-top:5%;
    }
}
label,
.label {
    cursor:pointer;
    display:inline-block;
    font-size: 99%;
    width:100%;
    line-height:1.5rem;
    margin-bottom: $baseline/4;
    font-weight: 500;
}
.form-label--radio {
    padding-top:.25rem;
}
.field {
    display:block;
    padding:$baseline/6 $gutter/6;
    line-height:1.5rem;
    width:100%;
    min-height:2.5rem;
    cursor: pointer;
    border: 1px solid #fff;
    //border-bottom:1px solid $grey;
    border-bottom:1px solid rgba(0,0,0, 0.2);
    cursor: pointer;
    &::-webkit-input-placeholder {color:$grey;}
        &:focus {
            //border-bottom-color: $body-font-color;
            border-bottom-color: $brand-dark-blue;
            background:#fff;
        }
        &[disabled]{
            cursor:auto;
            background: transparent;
        }
}
.field--number {
    max-width:120px;
}
legend {
    font-size: 1rem;
    margin-bottom:$baseline/2;
}
form,
.form {
    //background-color:$light-grey;
    background:$bg-color;
    padding:$baseline $gutter;
}
.form--sectioned,
.form--sectioned form {
    background-color:transparent;
    padding:0;
}

.form-divider {
    border-top: 1px solid black;
    padding-top: $baseline/2;
    margin-top: $baseline*2;
}
.form-header {
    @media(max-width:$bp-small){
        padding-left:$gutter;
    }
    padding-top:$baseline;
}
.form-meta {
    margin-bottom: $baseline;
}
.form-meta--heading {
    font-size:1.1rem;
}
.form-subheading {
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
}
.form-help {
    font-size:$gamma-size;
    margin-bottom:$baseline/2;
}
.form-context {
    margin-bottom: 4px;
}
.form-section {
    position: relative;
    //background-color:$light-grey;
    background-color:$bg-color;
    padding:$baseline $gutter 0 $gutter;
    margin-bottom:3px;
        &:last-of-type {
            padding-bottom: $baseline;    
        }
        .legend {
            position: absolute;
            top:0;
            padding:$baseline 0 0 0;
            font:1.2rem $header-font;
                &+ .form-row {
                    margin-top:$baseline*2;    
                }
        }
}
.form-note {
    padding-top:$baseline/4;
    font-size:$gamma-size;
        a:hover {
            text-decoration: underline;
        }
}
/*
.on--form-context .form-context__panel,
.on--form-information .form-information__panel {
    @include visible;
    margin-top:$baseline;
}
*/
.form-context__panel,
.form-information__panel {
    @include hidden;
}
.form-context__panel,
.form-information__panel {
    &.active {
        @include visible;
        margin-top:$baseline;
            a {
                color:$brand-dark-blue;
                &:hover {
                    color:$brand-light-blue;
                }
            }
    }
}
.form-row {
    margin-bottom:$baseline;
    clear:both;
}
.form-row--date {
    position: relative;
    .field {
        width:50%;
        max-width:200px;
        min-width:150px;
        padding-left:30px;
            &:focus ~ .form-row--date__icon {
                fill:$brand-dark-blue
            }
    }
}
.form-row--date__icon {
    position: absolute;
    left:.5rem;
    fill:$grey;
    top:2.5rem;
}
.form-row--datalist {
    position: relative;
}
.form-currency {
    label {
        position: relative;
            &:before {
                content: '£';
                position: absolute;
                left:0;
                top:1.9rem;
                padding:$baseline/3;
                padding-left:.75rem;
                font-size:$beta-size;
                color:$grey;
            }
    }
    .field {
        padding-left:30px;
        width:50%;
        max-width:200px;
    }
}
.form-currency {}
.form-row--submit {
    text-align: right;
    margin-bottom: 0;
}
.form-row--radio-set {
    position: relative;
}
.form-row--checkbox,
.form-row--radio {
    margin-bottom: 0;
    label {
        width: calc(100% - 30px);
        vertical-align: top;
    }
    input {
        vertical-align: top;
        display: inline-block;
        position: relative;
        top:.25rem;
        margin-right:.5rem;
        width:auto;
        padding:0;
        min-height:0;
    }
}
.form-row--right {
    text-align:right
}
.form-row--centre {
    text-align:center
}
.form-textarea {
    min-height:8rem;
    /*
    @media(min-width:$bp-medium){
        min-width:250px;
    }
    @media(min-width:$bp-large){
        min-width:400px;
    }*/
}
.form-select {
    display: block;
    cursor:pointer;
    position: relative;
    line-height:1.25rem;
    background:#fff;
    position: relative;
    z-index: 1;
    max-width:650px;
        .field {
            background:transparent;
            position: relative;
            z-index:2;
                &:focus {
                    background:transparent;
                }
        }
        &:after {
            content:'';
            border-color: #999 transparent transparent;
            border-style: solid;
            border-width: 5px 5px 2.5px;
            display: inline-block;
            height: 0;
            width: 0;
            position:absolute;
            right:15px;
            top:1.2rem;
            z-index:1;
        }
}

@media screen and (min-width:0\0) and (min-resolution:.001dpcm) {
//ie9 hack
    .form-select {
        padding-right:10px !important;
    }
    .form-select__icon {
        display:none;
    }
}
.form-file {
    position:relative;
    display: block;
    cursor:pointer;
        input {
            z-index:1;
            opacity:0;
            cursor:pointer;
            height:2rem;
            width:100%;
                &:focus:before {
                    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016h6v-6h4l-7-7-7%207h4zm-4%202h14v2H5z%22/%3E%0A%3C/svg%3E');
                }
        }
        &:before {
            min-height:2.5rem;
            position:absolute;
            padding:$baseline/6 $gutter/6;
            line-height:2rem;
            background-color:#fff;
            border: 1px solid #fff;
            border-bottom:1px solid $grey;  
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23bbbbbb%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016h6v-6h4l-7-7-7%207h4zm-4%202h14v2H5z%22/%3E%0A%3C/svg%3E');
            background-repeat: no-repeat;
            background-position:right 20px center;
            width:100%;
            content:'Choose file';
            cursor:pointer;
        }
        &:after {
            right:$gutter;
        }
}
.form-file-name {
    display:block;
    padding:.5rem 0 0 0;
    font-size:$gamma-size;
    line-height:1.4;
}



.form-row-checkbox {
    position: relative;
    overflow: hidden;
    padding-left:1.5rem;
    margin-bottom:.5rem;
        input {
            width:auto;
            position: absolute;
            left:-100%;
            top:-100%;
        }
}
.form-table {
    .th,
    .td {
        @media(max-width:$bp-small){
            padding-left:0;
        }
        padding:.25rem $gutter/4;
    }
    [data-th]:before { 
        @media screen and (max-width:$bp-small) {
            top:0;
            left:0;
        }
    }
    .th--small,
    .form-permissions {
        position: relative;
        overflow: hidden;
        text-align: center;
        @media(min-width:$bp-small){
            width:72px;
        }
    }
    .th--small {
        /*
         input {
            width:auto;
            position: absolute;
            top:$baseline/2;
            left:-100%;
            opacity:0;
        }*/
        .form-permissions-th__label {
            display:inline;
        }
        .form-permissions-field {
            content:'';
            border:0 none;
            background-color:transparent;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            z-index:1;
            text-indent: 1000%;
            overflow: hidden;
        }
    }
    .tr:hover {
        background:transparent;
    }
    .tbody .tr:nth-child(even),
    .tbody .tr:nth-child(even):hover {
        background: transparent;
    }

    @media(max-width:$bp-small){
        .thead {
            max-height: none;
            position: relative;
            display: block;
                .tr {
                    padding-top:0;
                    padding-bottom:0;
                }
                .th {
                    width:auto;
                    padding-left:.75rem;
                }
                .th--small {
                    display:none;
                }
        }
    }
}
.form-permissions {
    position: relative;
    overflow: hidden;
        /*
        input {
            width:auto;
            position: absolute;
            top:$baseline/2;
            left:-100%;
            opacity:0;
        }
        .form-permissions-field {
            content:'';
            border:0 none;
            background-color:transparent;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E');
            background-repeat: no-repeat;
            background-position: center;
            width:18px;
            height:18px;
            margin:8px 0 0 0;
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            z-index:1;
            text-indent: 1000%;
            overflow: hidden;
        }
        input:checked ~ .form-permissions-field {
            border:0 none;
            background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23000000%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z%22/%3E%0A%3C/svg%3E');
            background-repeat: no-repeat;
        }*/
        .form-permissions-label {
            position:absolute;
            height:20px;
            z-index:2;
        }
}
/*
.form-permissions-th__label:before {
    content:'';
    border:0 none;
    background-color:transparent;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23ffffff%22%20height%3D%2214%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2214%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: center;
    position:absolute;
    left:0;
    margin-top:.25rem;
    width:16px;
    height:16px;
    z-index:1;
    text-indent: 1000%;
    overflow: hidden;
}
.form-permissions-th__field:checked ~ .form-permissions-th__label:before {
    border:0 none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20fill%3D%22%23ffffff%22%20height%3D%2214%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2214%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%0A%20%20%20%20%3Cpath%20d%3D%22M9%2016.17L4.83%2012l-1.42%201.41L9%2019%2021%207l-1.41-1.41z%22/%3E%0A%3C/svg%3E');
    background-repeat: no-repeat;
}
*/
.form-permissions-th__field {
    margin:0 2px 0 0;
    position:relative;
    top:2px;
}
.th .legend {
    margin-bottom:0;
}
.th-risk-calculation {
    width:80px;
}
.table--risk-calculation {
    margin-bottom:$baseline;
    width:100%;
    th {
        padding:.5rem;
        @media (max-width:$bp-large){
            display:table-cell
        }
    }
    td {
        vertical-align:middle;
        padding:.25rem;
    }
    .td-risk-calculation {
        width:80px
    }
}
@include placeholder(rgba(255,255,255, .7));
.form-search__container {
    right:75px;
    @media(min-width:$bp-small) {
        right:110px;
    }
    position: absolute;
}
.form-search {
    visibility: hidden;
    position: absolute;
    transition-duration: .1s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    //max-width: .1px;
    background:$dark-grey;
    z-index:5;
    position: absolute;
    right: 0;
    top:12px;
    //max-width:250px;
    width:100%;
        @media(min-width:$bp-large){
            max-width:400px;    
        } 
}
.form-search__input {
    border:0 none;
    background:transparent;
    border-bottom:1px solid rgba(255,255,255, .5);
    color:#fff;
    padding:0 30px 0 0;
    min-height:2rem;
        &:focus {
            border-bottom-color:#fff;
        }
}
.active {
    &.form-search__container {
        left:$gutter;
    }
    .form-search {
        visibility: visible;
    }
}

.form-row--inset {
    position: relative;
}
.form-row--inset__btn {
    position: absolute;
    right:0;
    top:3px;
    background-color:transparent;
}
.form-row__inset-icon {
    position: absolute;
    left:6px;
    top:8px;
    fill: $grey;
}
input:focus + .form-row__inset-icon {
    //fill: $body-font-color;
    fill: $brand-dark-blue;
}

.form-instructions {
    text-align: left;
    position: relative;
    padding:$baseline $gutter;
    background-color:$light-grey;
    @include clearfix;
    @media(max-width:$bp-small) {
        display:block;
        padding-right:120px;
    }

    @media(min-width:$bp-small) {
        margin:$baseline 0;
    }
}
.form-instructions__h1 {
    text-align:left;
    margin-bottom: $baseline/4;
    font-family: $tertiary-font;
}

//warning
.form-warning {
    @include hidden;
}
.form-warning.active {
    @include visible;
    margin-bottom:$baseline/2;
    position: relative;
    padding:$baseline $gutter $baseline $gutter*2;
    //background:darken($off-white, 5%);
    //background:rgba($warning-color, .9);
    background:rgba($warning-color, .1);
    border:1px solid rgba($warning-color, .9);
    color:$warning-color;
        svg {
            position: absolute;
            left:$gutter/2;
            top:$baseline;
            fill:$warning-color;
        }
}

.form-warning-message {
    display:inline-block;
    padding:.25rem 1rem .5rem .5rem;
    margin-top:$baseline/2;
    background:rgba($warning-color, .1);
    border:1px solid rgba($warning-color, .9);
    color:$warning-color;
    position:relative;
        svg {
            position:absolute;
            top:.5rem;
            fill:$warning-color;
        }
        span {
            display:block;
            padding: 5px 0 0 30px;
        }
}

//errors
.input-validation-error,
.form-error .field,
.form-error .Select-control {
    border-bottom: 1px solid $error-color;
}
.field-validation-error,
.form-error-message {
    font-size:$gamma-size;
    display: block;
    color:$error-color;
    padding-top: .25rem;
}
.form-select.form-error {
    margin-bottom:$baseline;
    position: relative;
}
.form-select .form-error-message {
    position: absolute;
}
.form-row--radio {
    &.form-error:first-of-type {
        padding-top:1.5rem;
    }
    .form-error-message {
        position: absolute;
        top:1.35rem;
        left:0;
    }
}
.risk-matrix .form-error-message {
    position: absolute;
    bottom:-1.75rem;
}

.hint-text {
    display:block;  
    font-size:0.8rem; 
    font-style: italic;
} 
.form-hint {
    color:$dark-grey;
}
.form-hint + textarea {
    margin-top: $baseline/2;
}