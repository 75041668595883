.page {
    @include clearfix;
    background:#fff;
    padding-bottom:$baseline*2;
    @media(min-width:$bp-small){
        padding-left:$gutter;
        padding-right:$gutter;
    }/*
    @media(min-width:$bp-medium){
        padding-left:$gutter*2;
        padding-right:$gutter*2;
    }*/
}
.page-inset {
    width:100%;
    margin:0 auto;
    @media(min-width:$bp-xsmall){
        width:90%;
    }
    @media(min-width:$bp-small){
        width:50%;
        max-width:680px;
    }
}
.page-inset--login {
    @media(min-width:$bp-small){
        width:30%;
    }
}
.page {
    .filter {
        margin-left:0;
        margin-right:0;
    }
    .table__container {
        padding-left:0;
        padding-right:0;
        position: relative;
    }
}