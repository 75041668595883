/* Header
-------------------------------------------------------------- */
.banner {
    background:$brand-dark-blue;
    min-height:50px;
}
.banner-inner {
    margin:0 auto;
    @media (min-width:$bp-medium) {
        padding:0 $gutter;
    }
}