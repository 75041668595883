.dataset {
    position: relative;
    padding:$baseline $gutter 0 $baseline;
    margin-bottom:4px;
    background:lighten($bg-color, 2%);
    @include clearfix;
    @media(min-width:$bp-small) {
        margin:0 $gutter 4px $gutter;
    }

    @media(min-width:$bp-large) {
        margin-left:$gutter*2;
        margin-right:$gutter*2;
    }
}
.dataset__h1 {
    font-size:$alpha-size;
}
.dataset-form {
    padding:0;
    background:transparent;

    @media(max-width:$bp-small){
        width:calc(100% - 100px);
        float:left;
    }
    .form-row--radio {
        position: relative;
        .label {
            display:block;
            padding-left:25px;
			font-size:$gamma-size;
        }
        input {
            position: absolute;
            left:0;
        }
    }
    .legend {
        font:$alpha-size $tertiary-font;
    }
    
}
