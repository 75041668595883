.react-datalist {
	left: 0;
	z-index: 3;
	list-style: none;
	padding: 0;
	background: #fff;
    box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
	text-shadow: none;
    border: 0 none;
    transition-timing-function: ease;
    transition: .3s cubic-bezier(.4,.2,.5,1.4);
    transform-origin: 1.43em -.43em;
        .react-datalist:before {
            content: "";
            position: absolute;
            top: -.43em;
            left: 1em;
            width: 0;
            height: 0;
            padding: .4em;
            background: #fff;
            border: inherit;
            border-right: 0;
            border-bottom: 0;
            transform: rotate(45deg);
        }
        .react-datalist-option{
            position: relative;
            padding: .2em .5em;
            cursor: pointer;
            list-style:none;
                &:hover,
                &-selected {
                    background:lighten(grey, 40%);
                    color: $body-font-color;
                }
        }
        .react-datalist-option {
            width:100%;
        }
}
