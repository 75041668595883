/*.alert {
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 4;
    padding: 1rem;
}
.alert--success {
    background-color: rgba(123, 184, 8, 0.2);
    border: 1px solid rgba(123, 184, 8, 0.9);
    color:darken($success-color, 15%);
    p {
        margin-bottom:0;
    }
}
.alert--warning{
    background-color:rgba($error-color, .1);
    border:1px solid rgba($error-color, .9);
    color:darken($error-color, 15%);
}
.alert--info {
    background-color:rgba($brand-light-blue, .1);
    border:1px solid rgba($brand-light-blue, .9);
    color:$brand-dark-blue;
}
 */

.alert {
    position: absolute;
    top: 60px;
    color:#fff;
    right: 10px;
    width: 320px;
    padding: 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 12px #999;
    text-align:center;
    border-radius: 2px;
    z-index: 2;
        p {
            margin-bottom:0;
            color:#fff;
        }
}
.alert--success {
    //background-color: rgba(123, 184, 8, 0.2);
    background-color: #51a351;
    //border: 1px solid rgba(123, 184, 8, 0.9);
    //color:darken($success-color, 15%);
}
.alert--warning{
    background-color: #f89406;
    //background-color:rgba($error-color, .1);
    //border:1px solid rgba($error-color, .9);
    //color:darken($error-color, 15%);
}
.alert--info {
    background-color: #2f96b4;
    //background-color:rgba($brand-light-blue, .1);
    //border:1px solid rgba($brand-light-blue, .9);
    //color:$brand-dark-blue;
}
.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
  text-align:center;
}

.notification {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
  .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
  }
  &:hover, &:focus {
    opacity: 1;
  }
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}


.notification-info {
  background-color: #2f96b4;
}

.notification-success {
  background-color: #51a351;
}

.notification-warning {
  background-color: #f89406;
}

.notification-error {
  background-color: #bd362f;
}